/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
	"use strict";
	$(function ($) {
				 //Detecting viewpot dimension
				 var vH = $(window).height();
			
				 //Adjusting Intro Components Spacing based on detected screen resolution
				 //$('#intro').css('height',vH);
				 $('.full-height,  body.pace-running').css('height',vH);
				 $('.intro, #mastwrap').css('min-height',vH);
			
			
				//Vertical Centering of natural content spcific elements (non-images)
				 $(function ($) {
						  /*if your element is an image then please use $(window).load() instead tha above function wrap, because we want the coding to take
						  effect when the image is loaded. */
						  
						  //get the width of the parent
						  var parent_height = $('.vertical-center').parent().height();
						  var image_height = $('.vertical-center').height();
						  
						  var top_margin = (parent_height - image_height)/2;
						  
						  //center it
						  $('.vertical-center').css( 'padding-top' , top_margin);
						  //uncomment the following if ithe element to be centered is an image
						  $('.vertical-center-img').css( 'margin-top' , top_margin);
						  
						  
				   });
			});
			
			
			

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
	  
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
		
	
		$('#home-carousel-06 .home-slide').mouseenter(function(){
		$('#home-carousel-06 .home-slide').css('opacity',0.3);
		$(this).css('opacity',1);
		});
		$('#home-carousel-06 .home-slide').mouseleave(function(){
			$('#home-carousel-06 .home-slide').css('opacity',1);
		});

		  $('#sm-trigger, .menu-close').on('click', function(){
			  $('#sm-trigger').toggleClass('active');
			  $('#mastwrap').toggleClass('sliding-toright');
			  $('#sm').toggleClass('menu-open');
			  $('#mastwrap').addClass('nav-opened');
		  });
		  $('#mastwrap').on('click', function(){
			  $('#mastwrap').removeClass('sliding-toright');
			  $('#sm').removeClass('menu-open');
		  });
		
	

		
		
		
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
		//Submenu Toggle
		$('.id-submenu, .sub-menu').hide();
		$('.current-menu-item').closest('.sub-menu').show();
		$('.has-sub, .menu-item-has-children a').mouseover(function (e) {
		
		var text = $(this).next('ul');
		
		if (text.is(':hidden')) {
			text.slideDown('200');
			$(this).children('span').html('&nbsp;&#x25B2;');		
		} else {
			text.slideUp('200');
			$(this).children('span').html('&nbsp;&#x25BC;');		
		}
		
		
		});
		
		$(document).ready(function($) {
			
				$(".scroll").click(function(event){ // When a link with the .scroll class is clicked
					event.preventDefault(); // Prevent the default action from occurring
					$('html,body').animate({scrollTop:$(this.hash).offset().top}, 1500); // Animate the scroll to this link's href value
				});
				
			});
			
		$('#sm-trigger').addClass('active');
		$('#mastwrap').addClass('sliding-toright');
		$('#sm').addClass('menu-open');
		$('#mastwrap').addClass('nav-opened');	
		
		setTimeout(
		  function() 
		  {
			//do something special
			$('#mastwrap').removeClass('sliding-toright');
			$('#sm').removeClass('menu-open');
		  }, 2000);
		  

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
		 //home with carousel:
		 var carousel = $("#home-silder");
		 
	    carousel.owlCarousel({
				navigation : false,
				pagination: false,
				responsive: true,
				singleItem : true,
				transitionStyle : "fade",
				touchDrag: true,
				mouseDrag: true,
				items: 3,
				itemsDesktop: [5000,1],
				itemsDesktopSmall: [1440,1],
				itemsTablet:[1024,1],
				itemsTabletSmall: [640,1],
				itemsMobile: [360,1],
				autoHeight: true,
				autoPlay: false,
				addClassActive : true
				
		  });
		  
		$(".next").click(function(e){
		carousel.trigger('owl.next');
		});
		$(".prev").click(function(e){
		carousel.trigger('owl.prev');
		});
		  
	    $("#events-lower-carousel").owlCarousel({
					navigation : false,
					pagination: false,
					responsive: true,
					transitionStyle : "fade",
					touchDrag: true,
					mouseDrag: true,
					items: 4
					
				});
		$("#dwell-lower-carousel").owlCarousel({
					navigation : false,
					pagination: false,
					responsive: true,
					transitionStyle : "fade",
					touchDrag: true,
					mouseDrag: true,
					items: 4
					
				});
				
				
				
				
				
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS'
		$("#home-silder").trigger('owl.goTo', 1);
		
		
		
		
		
      }
    },
    // About us page, note the change from about-us to about_us.
    'about': {
      init: function() {
      },
	  finalize: function(){}
    },
	'process':{
		 init: function() {
			//home with carousel:
				
				
				 $("#posts-carousel").owlCarousel({
					navigation : false,
					pagination: false,
					responsive: true,
					items:4,
					touchDrag: true,
					mouseDrag: true,
					itemsDesktop: [5000,4],
					itemsDesktopSmall: [1440,1],
					itemsTablet:[1024,1],
					itemsTabletSmall: [640,1],
					itemsMobile: [360,1],
					autoHeight: true,
					autoPlay: true
				});
				
				  $("#process-wrap").okvideo({ source: 'XE7xqSHRURk',
                    volume: 25,
                    loop: true,
                    hd:true,
                    adproof: true,
                    annotations: false,
					controls: true
					}); 
			/*	$('.link').on('click', function(event){
					var $this = $(this);
					if($this.hasClass('sliding')){
					  $this.parent().removeAttr('style').removeClass('sliding');
					} else{
					  $this.parent().css('width','100%').addClass('sliding');
					}
				  });*/
      		},
	  finalize: function(){
		 
		  $('.owl-item').css('background-color','rgba(0,0,0,0)');
		  $('.pad-common').css('background-color','rgba(216,216,216,0.75)');
		  $('#okplayer').css('z-index','-1');
		
		  }
		
	},
	'event_domes': {
      init: function() {
		
		  
      },
	  finalize: function(){
		  
         $("#events-lower-carousel").owlCarousel({
					navigation : false,
					pagination: false,
					responsive: true,
					transitionStyle : "fade",
					touchDrag: true,
					mouseDrag: true,
					items: 4
					
				});
		  
		  
		  }
    },
	'dwelling_domes': {
      init: function() {
		
      },
	  finalize: function(){ 
	  
	  $("#dwell-lower-carousel").owlCarousel({
					navigation : false,
					pagination: false,
					responsive: true,
					transitionStyle : "fade",
					touchDrag: true,
					mouseDrag: true,
					items: 4
					
				});
				
		}
    },
	'portfolio': {
		init: function() {
			
		},
		finalize: function(){
			$("#nanoGallery").nanoGallery({
				kind: 'flickr',
				userID: '43806142@N03',
				thumbnailWidth: 'auto',
				thumbnailHeight: 250,
				colorSchemeViewer: 'dark',
				breadcrumbAutoHideTopLevel: true,
				thumbnailLazyLoad: true,
				theme: 'light',
				i18n: { 
					thumbnailImageDescription: 'View Photo', 
					thumbnailAlbumDescription: 'Open Album' 
				},
				thumbnailLabel: { 
					display: true, 
					position: 'onBottom', 
					hideIcons: false, 
					align: 'center',
					titleMaxLength: 20 
				 }
			  });
    	},
    },
	'all_domes':{
		init: function() {
			
		},
		finalize: function(){
			$("#nanoGallery").nanoGallery({
				kind: 'flickr',
				userID: '43806142@N03',
				thumbnailWidth: 'auto',
				thumbnailHeight: 250,
				colorSchemeViewer: 'dark',
				breadcrumbAutoHideTopLevel: true,
				thumbnailLazyLoad: true,
				theme: 'light',
				i18n: { 
					thumbnailImageDescription: 'View Photo', 
					thumbnailAlbumDescription: 'Open Album' 
				},
				thumbnailLabel: { 
					display: true, 
					position: 'onBottom', 
					hideIcons: false, 
					align: 'center',
					titleMaxLength: 20 
				 }
			  });
    	},
    },
	'huge_domes': {
		init: function() {
			
		},
		finalize: function(){
			$("#nanoGallery").nanoGallery({
				kind: 'flickr',
				userID: '43806142@N03',
				thumbnailWidth: 'auto',
				thumbnailHeight: 250,
				colorSchemeViewer: 'dark',
				breadcrumbAutoHideTopLevel: true,
				thumbnailLazyLoad: true,
				theme: 'light',
				albumList:'72157651123639817',
				i18n: { 
					thumbnailImageDescription: 'View Photo', 
					thumbnailAlbumDescription: 'Open Album' 
				},
				thumbnailLabel: { 
					display: true, 
					position: 'onBottom', 
					hideIcons: false, 
					align: 'center',
					titleMaxLength: 20 
				 }
			  });
			window.location = "/event-domes/huge-domes/#nanogallery/nanoGallery/72157651123639817"; 
    	},
    },
	'projection_domes': {
     init: function() {
			
		},
		finalize: function(){
			$("#nanoGallery").nanoGallery({
				kind: 'flickr',
				userID: '43806142@N03',
				thumbnailWidth: 'auto',
				thumbnailHeight: 250,
				colorSchemeViewer: 'dark',
				breadcrumbAutoHideTopLevel: true,
				thumbnailLazyLoad: true,
				theme: 'light',
				albumList:'72157649260348763',
				i18n: { 
					thumbnailImageDescription: 'View Photo', 
					thumbnailAlbumDescription: 'Open Album' 
				},
				thumbnailLabel: { 
					display: true, 
					position: 'onBottom', 
					hideIcons: false, 
					align: 'center',
					titleMaxLength: 20 
				 }
			  });
		window.location = "/event-domes/projection-domes/#nanogallery/nanoGallery/72157649260348763"; 
    	},
    },
	'printed_domes': {
     init: function() {
			
		},
		finalize: function(){
			$("#nanoGallery").nanoGallery({
				kind: 'flickr',
				userID: '43806142@N03',
				thumbnailWidth: 'auto',
				thumbnailHeight: 250,
				colorSchemeViewer: 'dark',
				breadcrumbAutoHideTopLevel: true,
				thumbnailLazyLoad: true,
				theme: 'light',
				albumList:'72157651166644249',
				i18n: { 
					thumbnailImageDescription: 'View Photo', 
					thumbnailAlbumDescription: 'Open Album' 
				},
				thumbnailLabel: { 
					display: true, 
					position: 'onBottom', 
					hideIcons: false, 
					align: 'center',
					titleMaxLength: 20 
				 }
			  });
			window.location = "/event-domes/printed-domes/#nanogallery/nanoGallery/72157651166644249"; 
    	},
    },
	'custom_domes': {
     init: function() {
			
		},
		finalize: function(){
			$("#nanoGallery").nanoGallery({
				kind: 'flickr',
				userID: '43806142@N03',
				thumbnailWidth: 'auto',
				thumbnailHeight: 250,
				colorSchemeViewer: 'dark',
				breadcrumbAutoHideTopLevel: true,
				thumbnailLazyLoad: true,
				theme: 'light',
				albumList:'72157649484474084',
				i18n: { 
					thumbnailImageDescription: 'View Photo', 
					thumbnailAlbumDescription: 'Open Album' 
				},
				thumbnailLabel: { 
					display: true, 
					position: 'onBottom', 
					hideIcons: false, 
					align: 'center',
					titleMaxLength: 20 
				 }
			  });
			  window.location = "/event-domes/custom-domes/#nanogallery/nanoGallery/72157649484474084"; 
    	},
    },
	'geodesic-dome-kit': {
      init: function() {
      },
	  finalize: function(){}
    },
	'dome-specs': {
      init: function() {
      },
	  finalize: function(){}
    },
	'faqs': {
      init: function() {
		  
		  $('#va-accordion').vaccordion({
					// the accordion's width
					accordionW      : $(window).width(),
					// the accordion's height
					accordionH      : $(window).height(),
					// number of visible slices
					visibleSlices   :4,
					// the height of a opened slice
					// should not be more than accordionH
					expandedHeight  : 450,
					// speed when opening / closing a slice
					animSpeed       : 350,
					// easing when opening / closing a slice
					animEasing      : 'jswing',
					// opacity value for the collapsed slices
					animOpacity     : 0.1,
					// time to fade in the slice's content
					contentAnimSpeed: 1500,
					// if this is set to false,
					// we collapse any opened slice
					// before sliding
					savePositions   : true
				});
		  
		  
      },
	  finalize: function(){
		  
		  
	  },
    },
	'contact': {
		init: function() {
			
			
			
			
		},
		finalize: function(){
			
			
			
    	},
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

 /* ********* WINDOW LOAD ********** */
jQuery(window).load(function() {
		
			// load screen
			jQuery('.loadreveal').addClass('reveal');
			jQuery('#loadscreen').stop().animate( { opacity: 0 }, 200, function() {
				jQuery('body.home').removeClass('loading');
				jQuery(this).hide();
			});
		
	
	});

